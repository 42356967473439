<template>
  <el-dialog
      title="查看项目"
      :close-on-click-modal="true"
      :visible.sync="coalInfoDialog"
      v-if="coalInfoDialog"
      @close="closeDialog"
      width="80%"
      append-to-body
  >

    <el-form :model="addForm"  ref="addForm"  label-width="100px"  v-loading="this.loading">
      <el-tabs :tab-position="'left'" @tab-click="tabEvents">
        <el-tab-pane label="项目信息" name="0">
          <el-row style="margin-bottom: 10px">
            <el-col :span="8">
              <el-form-item label="项目Id" prop="coalId">
                <el-input style="width: 66%;" v-model="addForm.coalId" placeholder="Id" readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="项目名称" prop="coalName">
                <el-input style="width: 66%;" v-model="addForm.coalName" placeholder="名称" readonly="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="地址信息" prop="address">
                <el-input style="width: 66%;" v-model="addForm.address" placeholder="地址"  readonly="true"d></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 10px">
            <el-col :span="8">
              <el-form-item label="产量信息" prop="output">
                <el-input style="width: 66%;" v-model="addForm.output" placeholder="产量"  readonly="true"d></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="煤质信息" prop="quality">
                <el-input style="width: 66%;" v-model="addForm.quality" placeholder="煤质"  readonly="true"d></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="煤层埋深" prop="miningWay">
                <el-input style="width: 66%;" v-model="addForm.miningWay" placeholder="开采"  readonly="true"d></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 10px">
            <el-col :span="8">
              <el-form-item label="煤层倾角" prop="disaster">
                <el-input style="width: 66%;" v-model="addForm.disaster" placeholder="灾害"  readonly="true"d></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="服务年限" prop="serviceNum">
                <el-input style="width: 66%;" v-model="addForm.serviceNum" placeholder="年限"  readonly="true"d></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="创建日期" prop="createDate">
                <el-input style="width: 66%;" v-model="addForm.createDate" placeholder="日期"  readonly="true"d></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="margin-bottom: 10px">
            <el-col :span="8">
              <el-form-item label="摺曲影响" prop="foldImpact">
                <el-input
                    style="width: 66%"
                    v-model="addForm.foldImpact"
                    placeholder="请输入内容"
                    readonly="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="断层影响" prop="faultImpact">
                <el-input
                    style="width: 66%"
                    v-model="addForm.faultImpact"
                    placeholder="请输入内容"
                    readonly="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="陷落柱影响" prop="trapColumnImpact">
                <el-input
                    style="width: 66%"
                    v-model="addForm.trapColumnImpact"
                    placeholder="请输入内容"
                    readonly="true"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="margin-bottom: 10px">
            <el-col :span="8">
              <el-form-item label="围岩稳定性" prop="surroundingRockStability">
                <el-input
                    style="width: 66%"
                    v-model="addForm.surroundingRockStability"
                    placeholder="请输入内容"
                    readonly="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="煤层自燃倾向性" prop="seamSpontaneousCombustionPropensity">
                <el-input
                    style="width: 66%"
                    v-model="addForm.seamSpontaneousCombustionPropensity"
                    placeholder="请输入内容"
                    readonly="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="瓦斯等级" prop="gasGrade">
                <el-input
                    style="width: 66%"
                    v-model="addForm.gasGrade"
                    placeholder="请输入内容"
                    readonly="true"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="margin-bottom: 10px">
            <el-col :span="8">
              <el-form-item label="矿井水文地质条件" prop="mineConditionsHydrogeological">
                <el-input
                    style="width: 66%"
                    v-model="addForm.mineConditionsHydrogeological"
                    placeholder="请输入内容"
                    readonly="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="冲击倾向性" prop="impactPropensity">
                <el-input
                    style="width: 66%"
                    v-model="addForm.impactPropensity"
                    placeholder="请输入内容"
                    readonly="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="煤尘爆炸危险性" prop="coalDustExplosionRisk">
                <el-input
                    style="width: 66%"
                    v-model="addForm.coalDustExplosionRisk"
                    placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 10px">
            <el-col :span="8">
              <el-form-item label="矿井水文地质条件" prop="mineConditionsHydrogeological">
                <el-input
                    style="width: 66%"
                    v-model="addForm.mineConditionsHydrogeological"
                    placeholder="请输入内容"
                    readonly="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="冲击倾向性" prop="impactPropensity">
                <el-input
                    style="width: 66%"
                    v-model="addForm.impactPropensity"
                    placeholder="请输入内容"
                    readonly="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="煤尘爆炸危险性" prop="coalDustExplosionRisk">
                <el-input
                    style="width: 66%"
                    v-model="addForm.coalDustExplosionRisk"
                    placeholder="请输入内容"
                    readonly="true"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="margin-bottom: 10px">
            <el-col :span="8">
              <el-form-item label="热害等级" prop="heatDamageGrade">
                <el-input
                    style="width: 66%"
                    v-model="addForm.heatDamageGrade"
                    placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="系统" name="1">
          <el-form-item label="系统">
            <div v-for="systemName in addForm.data" :key="systemName.id">
              <el-input style="width: 45%;margin-bottom: 5px" v-model="systemName.name" placeholder="系统名称"  readonly="true"d></el-input>
              <el-input style="width: 45%;margin-bottom: 5px" v-model="systemName.remark" placeholder="系统描述" type="textarea"  readonly="true"d></el-input>
            </div>
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane label="子系统" name="2">
          <el-form-item label="子系统">
            <el-select style="width: 91%;margin-bottom: 5px" v-model="systemSelected.id" placeholder="请选择项目系统">
              <el-option v-for="item in addForm.data" :key="item.id" :label="item.name" :value="item.id" @click.native="selectSystem(item)">
              </el-option>
            </el-select>
            <div v-for="subSystemName in this.systemSelected.subsystemName" :key="subSystemName.id">
              <el-input style="width: 45%;margin-bottom: 5px" v-model="subSystemName.name" placeholder="子系统名称"  readonly="true"d></el-input>
              <el-input style="width: 45%;margin-bottom: 5px" v-model="subSystemName.remark" placeholder="子系统描述"  readonly="true"d></el-input>
            </div>
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane label="设备" name="3"><el-form-item label="设备">
          <el-select style="width: 91%;margin-bottom: 5px" v-model="subSystemSelected.id" placeholder="请选择项目子系统">
            <el-option v-for="item in this.systemSelected.subsystemName" :key="item.id" :label="item.name" :value="item.id" @click.native="selectSubSystem(item)">
            </el-option>
          </el-select>
          <div v-for="device in this.subSystemSelected.device" :key="device.id">
            <el-input style="width: 45%;margin-bottom: 5px" v-model="device.name" placeholder="设备名称"  readonly="true"d></el-input>
            <el-input style="width: 45%;margin-bottom: 5px" v-model="device.remark" placeholder="设备描述"  readonly="true"d></el-input>
          </div>
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane label="附属设备" name="4">
          <el-form-item label="附属设备">
            <el-select style="width: 91%;margin-bottom: 5px" v-model="deviceSelected.id" placeholder="请选择设备">
              <el-option v-for="item in this.subSystemSelected.device" :key="item.id" :label="item.name" :value="item.id" @click.native="selectDevice(item)"></el-option>
            </el-select>
            <div v-for="subDevice in this.deviceSelected.subDevice" :key="subDevice.id">
              <el-input style="width: 45%;margin-bottom: 5px" v-model="subDevice.name" placeholder="附属设备名称"  readonly="true"d></el-input>
              <el-input style="width: 45%;margin-bottom: 5px" v-model="subDevice.remark" placeholder="附属设备描述"  readonly="true"d></el-input>
            </div>
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane label="数据项" name="5">
          <el-form-item label="数据项">
            <el-select style="width: 91%;margin-bottom: 5px" v-model="subDeviceSelected.id" placeholder="请选择数据项">
              <el-option v-for="item in this.deviceSelected.subDevice" :key="item.id" :label="item.name" :value="item.id" @click.native="selectSubDevice(item)">
              </el-option>
            </el-select>
            <div v-for="subDeviceField in this.subDeviceSelected.subDeviceField" :key="subDeviceField.id">
              <el-input style="width: 45%;margin-bottom: 5px" v-model="subDeviceField.name" placeholder="数据项名称"  readonly="true"d></el-input>
              <el-input style="width: 45%;margin-bottom: 5px" v-model="subDeviceField.remark" placeholder="数据项描述"  readonly="true"d></el-input>
            </div>
          </el-form-item>
        </el-tab-pane>

<!--        <el-tab-pane label="确认" name="6">-->
<!--          <el-row style="margin-bottom: 10px">-->
<!--            <el-col :span="8">-->
<!--              <el-form-item label="新系统简介" prop="remark" style="margin-bottom: 10px">-->
<!--                <el-input style="width: 100%" type="textarea" :rows="3" v-model="addForm.remark" placeholder="请输入简介信息">-->
<!--                </el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->
<!--          <el-form-item label="当前操作员" prop="currOperatorName" style="margin-bottom: 10px">-->
<!--            <el-input style="width: 20%" class="small-input" v-model="addForm.currOperatorName" placeholder="管理员user"-->
<!--                       readonly="true"d></el-input>-->
<!--          </el-form-item>-->

<!--          <el-form-item style="margin-bottom: 10px">-->
<!--            <el-button type="primary" @click="submitForm('addForm')">立即创建</el-button>-->
<!--            <el-button @click="resetForm('addForm')">重置</el-button>-->
<!--          </el-form-item>-->
<!--        </el-tab-pane>-->
      </el-tabs>
    </el-form>

<!--    <span slot="footer" class="dialog-footer">-->
<!--          <el-button @click="giveUpAdd()">取 消</el-button>-->
<!--          <el-button type="primary" @click="systemDialog = false">保 存</el-button>-->
<!--        </span>-->
  </el-dialog>
</template>

<script>
export default {
  name: "checkCoal",
  data() {
    return {
      coalId: 0,
      key: 0,
      value: '',
      systemValue: '',
      subSystemValue: '',
      deviceValue: '',
      subDeviceValue: '',
      subDeviceFieldValue: '',
      systemSelected: [
        {
          subsystemName:[
            {
              id: '1',
              name: '1',
              remark: '1'
            }, {
              id: '2',
              name: '2',
              remark: '2'
            }
          ]
        }
      ],
      subSystemSelected: [],
      deviceSelected: [],
      subDeviceSelected: [],
      fieldSelected: [],
      chooseId: 0,
      loading: true,
      coalInfoDialog: false,
      addForm: {
        data: [
          {
            id: '',
            name: '',
            remark: ''
          }
        ]
      },
      showTab: false
    }
  },
  methods: {
    init(coalId) {
      this.coalId = coalId;
      // this.initData();
      this.getCoalInfo();
      this.coalInfoDialog = true;
    },
    tabEvents(tab) {
      if (tab.index == 1) {
        this.getSystemInfo()
      }
      if (tab.index == 2) {
        console.log(this.systemSelected)
      }
    },
    getCoalInfo() {
      this.getRequest('/coalManage/getCoalInfoByCoalId?coalId=' + this.coalId).then((resp) => {
        if (resp) {
          console.log(resp.data)
          this.addForm.coalId = resp.data.id;
          this.addForm.coalName = resp.data.name;
          this.addForm.address = resp.data.address;
          this.addForm.quality = resp.data.coalquality;
          this.addForm.disaster = resp.data.disasterType;
          this.addForm.miningWay = resp.data.miningMethod;
          this.addForm.output = resp.data.yield;
          this.addForm.serviceNum = resp.data.lengthOfService;
          this.addForm.createDate = resp.data.createdate;
          this.loading = false;
          this.addForm.foldImpact = resp.data.foldImpact
          this.addForm.faultImpact = resp.data.faultImpact
          this.addForm.trapColumnImpact = resp.data.trapColumnImpact
          this.addForm.surroundingRockStability = resp.data.surroundingRockStability
          this.addForm.seamSpontaneousCombustionPropensity = resp.data.seamSpontaneousCombustionPropensity
          this.addForm.gasGrade = resp.data.gasGrade
          this.addForm.mineConditionsHydrogeological = resp.data.mineConditionsHydrogeological
          this.addForm.impactPropensity = resp.data.impactPropensity
          this.addForm.coalDustExplosionRisk = resp.data.coalDustExplosionRisk
          this.addForm.heatDamageGrade = resp.data.heatDamageGrade
        }
      })
    },
    getSystemInfo() {
      this.getRequest('/coalManage/getSystemInfoByCoalId?coalId=' + this.coalId).then((resp) => {
        if (resp) {

          console.log(resp.data)
          this.addForm.data = resp.data
          console.log(this.addForm)
          this.loading = false;
        }
      })
    },
    getSubSystemInfo() {
      this.getRequest('/coalManage/getSubSystemInfoBySystemId?systemId=' + this.systemSelected.id).then((resp) => {
        if (resp.data[0] != null) {
          this.$set(this.systemSelected, 'subsystemName', resp.data)
        } else {
          this.$set(this.systemSelected, 'subsystemName', [{"id": 1, "name": "无", "remark": "无"}])
        }
      })
    },
    getEquipmentInfo() {
      this.getRequest('/coalManage/getEquipmentInfoBySubSystemId?subSystemId=' + this.subSystemSelected.id).then((resp) => {
        if (resp.data[0] != null) {
          this.$set(this.subSystemSelected, 'device', resp.data)
        } else {
          this.$set(this.subSystemSelected, 'device', [{"id": 1, "name": "无", "remark": "无"}])
        }
      })
    },
    getSubEquipmentInfo() {
      this.getRequest('/coalManage/getSubEquipmentInfoByEquipmentId?equipmentId=' + this.deviceSelected.id).then((resp) => {
        if (resp.data[0] != null) {
          this.$set(this.deviceSelected, 'subDevice', resp.data)
        } else {
          this.$set(this.deviceSelected, 'subDevice', [{"id": 1, "name": "无", "remark": "无"}])
        }
      })
    },
    getFieldInfo() {
      this.getRequest('/coalManage/getFieldInfoBySubEquipmentId?subEquipmentId=' + this.subDeviceSelected.id).then((resp) => {
        if (resp.data[0] != null) {
          this.$set(this.subDeviceSelected, 'subDeviceField', resp.data)
        } else {
          this.$set(this.subDeviceSelected, 'subDeviceField', [{"id": 1, "name": "无", "remark": "无"}])
        }
      })
    },
    initData() {

      this.getRequest('/coalManage/getCoalAllInfoByCoalId?id=' + this.coalId).then((resp) => {
        if (resp.data){
          console.log(resp.data)
          this.addForm.coalId = resp.data.id;
          this.addForm.coalName = resp.data.name;
          this.addForm.address = resp.data.address;
          this.addForm.quality = resp.data.coalQuality;
          this.addForm.disaster = resp.data.disasterType;
          this.addForm.miningWay = resp.data.miningMethod;
          this.addForm.output = resp.data.yield;
          this.addForm.serviceNum = resp.data.lengthOfService;
          this.addForm.data = resp.data.data;

          console.log(this.addForm.data)
          this.loading = false;
        }
      }
      )
    },
    closeDialog() {
      this.coalInfoDialog = false;
      this.loading = true;
      this.$refs["addForm"].resetFields()
      Object.assign(this.$data, this.$options.data())
    },
    selectSystem(system) {
      console.log(system.id)
      Object.assign(this.systemSelected, system)
      // this.systemSelected = system;
      this.getSubSystemInfo()
      console.log(this.systemSelected)
    },
    // selectSystem(system) {
    //   this.systemSelected = system;
    //   this.systemValue = system.name;
    //   console.log(system)
    //   console.log(this.value)
    //   // this.chooseVisible = true;
    // },
    selectSubSystem(subSystem) {
      Object.assign(this.subSystemSelected, subSystem)
      console.log(this.subSystemSelected)
      this.getEquipmentInfo()
    },
    // selectSubSystem(subSystem) {
    //   this.subSystemSelected = subSystem;
    //   this.subSystemValue = subSystem.value;
    //   console.log(subSystem);
    //   console.log(this.value)
    // },
    selectDevice(device) {
      Object.assign(this.deviceSelected, device)
      console.log(this.deviceSelected);
      this.getSubEquipmentInfo()
    },
    // selectDevice(device) {
    //   this.deviceSelected = device;
    //   this.deviceValue = device.value;
    //   console.log(device);
    //   console.log(this.value)
    // },
    selectSubDevice(subDevice) {
      Object.assign(this.subDeviceSelected, subDevice);
      console.log(this.subDeviceSelected);
      this.getFieldInfo()
    },
    // selectSubDevice(subDevice) {
    //   this.subDeviceSelected = subDevice;
    //   this.subDeviceValue = subDevice.value;
    //   console.log(subDevice);
    //   console.log(this.value)
    // },
    selectField(Field) {
      this.FieldSelected = Field;
      console.log(Field);
    }
  }
}
</script>

<style scoped>

</style>
